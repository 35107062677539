import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { ModalOption, ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy {
  modalOpen = false;
  modalData: ModalOption | null = null;
  loading: boolean = false;
  private isBrowser: boolean;

  constructor(
    private modalService: ModalService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    // Überprüfen, ob der Code im Browser läuft
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  private _unsubscribeAll: Subject<unknown> = new Subject<unknown>();

  ngOnInit(): void {
    this.modalService.data$.subscribe(data => {
      this.modalData = data;
    });
    this.modalService.status$.subscribe(status => {
      this.modalOpen = status;
      this.toggleBodyScroll(status);
    });
    this.modalService.loading$.subscribe(loading => {
      this.loading = loading;
    });
  }

  ngOnDestroy(): void {
    this.toggleBodyScroll(false);
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  toggleBodyScroll(enable: boolean): void {
    if (this.isBrowser) {
      const body = document.body;
      if (enable) {
        body.classList.add('modal-open');
      } else {
        body.classList.remove('modal-open');
      }
    }
  }

  onClickAction(handler?: () => Promise<void>): void {
    const effectiveHandler = handler ? handler : () => Promise.resolve();
    this.modalService.action(effectiveHandler);
  }

  onClickClose(): void {
    this.modalService.close();
  }
}
